import React from "react";
import Footer from "../components/Footer";
import NavBarTwo from "../components/NavbarTwo";
import { SEO } from "../components/seo";

const impressum = () => {
    return <>
    <NavBarTwo/>
        
        <section className="text-gray-600 body-font" id="main">
  <div className="container mx-auto mx-auto flex px-5 py-48 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
     <div class="w-12 h-1 bg-primary rounded mt-2 mb-4"></div>
      <h1 className=" title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">Impressum</h1><br/>
      <p className="sm:text-lg mb-8 leading-relaxed">
        Roomando GmbH<br/>
        Geschäftsführer: Michael Kurz<br/><br/>
        Im Haldenrain 58<br/>
        70806 Kornwestheim<br/><br/>

        Tel. 07154-80 85 771<br/>
Fax 07154-80 85 772<br/>
USt.ID: DE  218399472<br/>

Handelsregister: HRB 203886<br/>

Registergericht: Stuttgart<br/>
        
        </p>

    </div>
    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
      
      {/* <img className="object-cover object-center rounded" alt="hero" src="orthotest.jpg"/> */}
    </div>
  </div>
</section>   
    <Footer/>
    </>
}

export default impressum;

export const Head = ({data}) => {
  return (
  <>
 
  {/* <meta name="description" content="This is an example of a meta description. This will often show up in search results."/> */}
  {/* <title> </title> */}
  <SEO/>
  </>
  )
}